
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';
import { ApiToStorage } from '@/components/data-operations/runs-collections';

@Component({
	components: { ApiToStorageCollectionListing: ApiToStorage.collectionListing },
})
export default class StorageToStorageRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
